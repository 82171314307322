var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("header", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.goManagerPageFn
    }
  }, [_vm._v("登录管理后台")])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };