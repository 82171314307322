var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "payslip"
  }, [_vm.ADMIN_ROLE_LIST.includes(_vm.roleName) ? _c("MyHeader") : _vm._e(), _c("PayslipListChild", {
    ref: "payslipListChild",
    staticClass: "main"
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };