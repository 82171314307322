import config from '@/config';
import { mapGetters, mapMutations } from 'vuex';
import MyHeader from '@/components/MyHeader';
import PayslipListChild from '@/components/payslipList/PayslipListChild';
export default {
  name: 'Payslip',
  components: {
    MyHeader,
    PayslipListChild
  },
  beforeRouteEnter(to, from, next) {
    // 判断是否来自detail
    if (from.path === '/payslip-detail') {
      next(vm => {
        vm.reShowYearMonthFn();
      });
    } else {
      next(vm => {
        vm.clearYearMonthFn();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/payslip-detail') {
      // 保存当前年月参数
      const params = this.$refs.payslipListChild.params;
      this.setSalaryListQuery(params);
    }
    next();
  },
  data() {
    return {
      ADMIN_ROLE_LIST: config.ADMIN_ROLE_LIST
    };
  },
  computed: {
    ...mapGetters(['roleName'])
  },
  methods: {
    ...mapMutations('payslip', ['setSalaryListQuery']),
    reShowYearMonthFn() {
      // 回显年月参数
      this.$nextTick(() => {
        this.$refs.payslipListChild.recoverYearMonthFn();
      });
    },
    clearYearMonthFn() {
      // 清空年月参数
      this.setSalaryListQuery({});
      this.$nextTick(() => {
        this.$refs.payslipListChild.initYearFn();
      });
    }
  }
};