import "core-js/modules/es.array.push.js";
export default {
  name: 'MyHeader',
  methods: {
    goManagerPageFn() {
      this.$router.push({
        path: '/payslip-manager'
      });
    }
  }
};